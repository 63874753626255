<script setup lang="ts">
import { LineItem } from '@shopware-pwa/types'
import { useCartNotification } from '~/composables/useCartNotification'
import { getShopPermalink } from '~/lib'
const { getFormattedPrice } = usePrice()

const props = withDefaults(
  defineProps<{
    cartItem: LineItem
    maxQty?: number
  }>(),
  {
    maxQty: 100,
  },
)

const { cartItem } = toRefs(props)

const isLoading = ref(false)
const { codeErrorsNotification } = useCartNotification()
const { itemOptions, removeItem, itemTotalPrice, itemQuantity, isPromotion, changeItemQuantity } = useCartItem(cartItem)

const quantity = ref()
syncRefs(itemQuantity, quantity)

const updateQuantity = async (quantity: number | undefined) => {
  if (quantity === itemQuantity.value) return

  isLoading.value = true

  await changeItemQuantity(Number(quantity))
  codeErrorsNotification()

  isLoading.value = false
}
const debounceUpdate = useDebounceFn(updateQuantity, 800)

watch(quantity, () => debounceUpdate(quantity.value))

const removeCartItem = async () => {
  isLoading.value = true
  await removeItem()
  isLoading.value = false
}

const itemUrl = cartItem.value?.id && `${getShopPermalink()}/detail/${cartItem.value?.id}`
</script>

<template>
  <div class="wrapper">
    <img v-if="!isPromotion" :src="cartItem?.cover?.url" />
    <div class="details">
      <h3 class="text-small">
        <NuxtLink v-if="itemUrl" :to="itemUrl"> {{ cartItem.label }} </NuxtLink>
      </h3>
      <div class="quantity-price">
        <span class="text-small f-medium">{{ getFormattedPrice(itemTotalPrice) }}</span>
        <input
          v-if="!isPromotion"
          v-model="quantity"
          type="number"
          :disabled="isLoading"
          :min="cartItem.quantityInformation?.minPurchase || 1"
          :max="cartItem.quantityInformation?.maxPurchase || maxQty"
          :step="cartItem.quantityInformation?.purchaseSteps || 1"
          name="quantity"
        />
      </div>
      <button class="close" type="button" :disabled="isLoading" @click="removeCartItem">
        <Icon name="large-close" />
      </button>
    </div>
  </div>
</template>
<style scoped lang="scss">
.wrapper {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 5fr;
  padding: 12px 0 24px 0;
  border-top: 1px solid var(--c-line-light);
  img {
    border-radius: 5px;
    box-shadow: var(--shadow);
  }
  .details {
    position: relative;
    h3 {
      padding-right: 20px;
    }
    .quantity-price {
      text-align: right;
      input {
        width: 90px;
        height: 54px;
        border: 1px solid var(--c-line-light);
        padding: 16px;
        text-align: center;
        margin-left: 16px;
      }
    }
    .close {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
</style>
